* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #ffffff !important;
  font-family: "'Montserrat', 'sans-serif'" !important;
}

a {
  text-decoration: none;
}

.MuiButton-root {
  text-transform: none !important;
}

.css-12nu9md {
  display: none !important;
}

.css-106d0ed {
  box-shadow: none !important;
}

@media (max-width: 420px) {
  css-106d0ed {
    box-shadow: none;
    border: 1px solid #eee;
  }
}
@media (min-width: 600px) {
  .makeStyles-root-1 {
    padding-top: 0px !important;
  }
}

@media (max-width: 420px) {
  a .makeStyles-resultCard-26 .css-106d0ed .e1cc0ogo1 {
    box-shadow: none !important;
  }
}
